//
// Labels
// --------------------------------------------------

.label {
 display: inline;
 padding: .2em .6em .3em;
 font-size: 75%;
 font-weight: bold;
 line-height: 1;
 color: @label-color;
 text-align: center;
 white-space: nowrap;
 vertical-align: baseline;
 border-radius: .25em;

 // Add hover effects, but only for links
 a& {
 &:hover,
 &:focus {
 color: @label-link-hover-color;
 text-decoration: none;
 cursor: pointer;
 }
 }

 // Empty labels collapse automatically (not available in IE8)
 &:empty {
 display: none;
 }

 // Quick fix for labels in buttons
 .btn & {
 position: relative;
 top: -1px;
 }
}

// Colors
// Contextual variations (linked labels get darker on :hover)

.label-default {
 .label-variant(@label-default-bg);
}

.label-primary {
 .label-variant(@label-primary-bg);
}

.label-success {
 .label-variant(@label-success-bg);
}

.label-info {
 .label-variant(@label-info-bg);
}

.label-warning {
 .label-variant(@label-warning-bg);
}

.label-danger {
 .label-variant(@label-danger-bg);
}
