// Single side border-radius

.border-top-radius(@radius) {
 border-top-right-radius: @radius;
 border-top-left-radius: @radius;
}
.border-right-radius(@radius) {
 border-bottom-right-radius: @radius;
 border-top-right-radius: @radius;
}
.border-bottom-radius(@radius) {
 border-bottom-right-radius: @radius;
 border-bottom-left-radius: @radius;
}
.border-left-radius(@radius) {
 border-bottom-left-radius: @radius;
 border-top-left-radius: @radius;
}
