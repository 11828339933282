// Grid system
//
// Generate semantic grid columns with these mixins.

// Centered container element
.container-fixed(@gutter: @grid-gutter-width) {
 margin-right: auto;
 margin-left: auto;
 padding-left: floor((@gutter / 2));
 padding-right: ceil((@gutter / 2));
 &:extend(.clearfix all);
}

// Creates a wrapper for a series of columns
.make-row(@gutter: @grid-gutter-width) {
 margin-left: ceil((@gutter / -2));
 margin-right: floor((@gutter / -2));
 &:extend(.clearfix all);
}

// Generate the extra small columns
.make-xs-column(@columns; @gutter: @grid-gutter-width) {
 position: relative;
 float: left;
 width: percentage((@columns / @grid-columns));
 min-height: 1px;
 padding-left: (@gutter / 2);
 padding-right: (@gutter / 2);
}
.make-xs-column-offset(@columns) {
 margin-left: percentage((@columns / @grid-columns));
}
.make-xs-column-push(@columns) {
 left: percentage((@columns / @grid-columns));
}
.make-xs-column-pull(@columns) {
 right: percentage((@columns / @grid-columns));
}

// Generate the small columns
.make-sm-column(@columns; @gutter: @grid-gutter-width) {
 position: relative;
 min-height: 1px;
 padding-left: (@gutter / 2);
 padding-right: (@gutter / 2);

 @media (min-width: @screen-sm-min) {
 float: left;
 width: percentage((@columns / @grid-columns));
 }
}
.make-sm-column-offset(@columns) {
 @media (min-width: @screen-sm-min) {
 margin-left: percentage((@columns / @grid-columns));
 }
}
.make-sm-column-push(@columns) {
 @media (min-width: @screen-sm-min) {
 left: percentage((@columns / @grid-columns));
 }
}
.make-sm-column-pull(@columns) {
 @media (min-width: @screen-sm-min) {
 right: percentage((@columns / @grid-columns));
 }
}

// Generate the medium columns
.make-md-column(@columns; @gutter: @grid-gutter-width) {
 position: relative;
 min-height: 1px;
 padding-left: (@gutter / 2);
 padding-right: (@gutter / 2);

 @media (min-width: @screen-md-min) {
 float: left;
 width: percentage((@columns / @grid-columns));
 }
}
.make-md-column-offset(@columns) {
 @media (min-width: @screen-md-min) {
 margin-left: percentage((@columns / @grid-columns));
 }
}
.make-md-column-push(@columns) {
 @media (min-width: @screen-md-min) {
 left: percentage((@columns / @grid-columns));
 }
}
.make-md-column-pull(@columns) {
 @media (min-width: @screen-md-min) {
 right: percentage((@columns / @grid-columns));
 }
}

// Generate the large columns
.make-lg-column(@columns; @gutter: @grid-gutter-width) {
 position: relative;
 min-height: 1px;
 padding-left: (@gutter / 2);
 padding-right: (@gutter / 2);

 @media (min-width: @screen-lg-min) {
 float: left;
 width: percentage((@columns / @grid-columns));
 }
}
.make-lg-column-offset(@columns) {
 @media (min-width: @screen-lg-min) {
 margin-left: percentage((@columns / @grid-columns));
 }
}
.make-lg-column-push(@columns) {
 @media (min-width: @screen-lg-min) {
 left: percentage((@columns / @grid-columns));
 }
}
.make-lg-column-pull(@columns) {
 @media (min-width: @screen-lg-min) {
 right: percentage((@columns / @grid-columns));
 }
}
