//
// Breadcrumbs
// --------------------------------------------------


.breadcrumb {
 padding: @breadcrumb-padding-vertical @breadcrumb-padding-horizontal;
 margin-bottom: @line-height-computed;
 list-style: none;
 background-color: @breadcrumb-bg;
 border-radius: @border-radius-base;

 > li {
 display: inline-block;

 + li:before {
 content: "@{breadcrumb-separator}\00a0"; // Unicode space added since inline-block means non-collapsing white-space
 padding: 0 5px;
 color: @breadcrumb-color;
 }
 }

 > .active {
 color: @breadcrumb-active-color;
 }
}
